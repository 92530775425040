import "whatwg-fetch"; // fetch polyfill
import { computed, Ref, unref } from "vue";
import { MaybeRef, useFetch } from "@vueuse/core";
import { useRouteWithLang } from "@/composables/useRouteWithLang";

interface UseAPIRequestResult<T> {
  execute: () => Promise<T>;
  isFetching: Ref<boolean>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: Ref<any>;
  data: Ref<T | null>;
}

export function useAPIRequest<T>(
  endpoint: MaybeRef<string>,
  requestOptions: RequestInit = {}
): UseAPIRequestResult<T> {
  const { currentLang } = useRouteWithLang();

  const baseUrl =
    process.env.VUE_APP_ENV === "local"
      ? process.env.VUE_APP_API_BASE_URL
      : currentLang.value === "cat"
      ? process.env.VUE_APP_API_BASE_URL_CAT
      : process.env.VUE_APP_API_BASE_URL_ES;

  const fullURL = computed(() => baseUrl + unref(endpoint));

  const requestOptionsWithDefaults: RequestInit = {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      ...requestOptions?.headers,
    },
    ...requestOptions,
  };

  const { execute, isFetching, error, data } = useFetch<T>(
    fullURL,
    requestOptionsWithDefaults,
    {
      immediate: false,
    }
  ).json();

  return {
    execute,
    isFetching,
    error,
    data,
  };
}
