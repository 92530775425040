import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import relativeTime from "dayjs/plugin/relativeTime";
import duration from "dayjs/plugin/duration";
import * as cat from "dayjs/locale/ca";
import * as es from "dayjs/locale/es";

dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(isBetween);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let langs: any = {};

function setLocaleES() {
  dayjs.locale("es");
  langs = {
    ...es,
    customFormats: {
      Ll: "D [de] MMMM",
    },
    hours_short: "h",
    hours_large: "horas",
    hour_large: "hora",
    minutes_short: "min",
    weekday_initials: ["D", "L", "M", "X", "J", "V", "S"],
  };
}

function setLocaleCAT() {
  dayjs.locale("ca");

  langs = {
    ...cat,
    customFormats: {
      Ll: "D MMMM", // Note: intentionally not adding "de" or "d'" here, is handled with some logic that depends on the month's initial vowel
    },
    hours_short: "h",
    hours_large: "hores",
    hour_large: "hora",
    minutes_short: "min",
    weekday_initials: ["D", "L", "M", "X", "J", "V", "S"],
  };
}

export function formatDay(isoDay: string, lang: string): string {
  return formatDayWithWeekdayAtStart(isoToTimestamp(isoDay), lang);
}

export const isoToTimestamp = (iso: string): number =>
  new Date(
    parseInt(iso.slice(0, 4)),
    parseInt(iso.slice(5, 7)) - 1,
    parseInt(iso.slice(8, 10))
  ).getTime() / 1000;

export const formatDateTimeRelativeForHumans = (
  timestamp: number,
  preffix = false,
  lang: string
): string => {
  if (lang === "es") {
    setLocaleES();
  } else {
    setLocaleCAT();
  }
  const date = dayjs.unix(timestamp);
  if (Math.abs(dayjs().diff(date, "month")) > 11) {
    return formatDateLong(timestamp, lang);
  }
  return date.fromNow(!preffix);
};

export const formatDayWithWeekday = (
  timestamp: number,
  lang: string
): string => {
  if (lang === "es") {
    setLocaleES();
  } else {
    setLocaleCAT();
  }

  const date = dayjs.unix(timestamp);

  return date.format("dddd DD.MM");
};

export const formatDay2 = (isoDay: string, lang: string): string => {
  if (lang === "es") {
    setLocaleES();
  } else {
    setLocaleCAT();
  }
  const date = dayjs.unix(isoToTimestamp(isoDay));
  return date.format("D MMMM");
};

export const formatDayWithWeekdayAtStart = (
  timestamp: number,
  lang: string
): string => {
  if (lang === "es") {
    setLocaleES();
  } else {
    setLocaleCAT();
  }

  const date = dayjs.unix(timestamp);

  return date.format("dddd, D MMMM");
};

export const formatDateLong = (timestamp: number, lang: string): string => {
  if (lang === "es") {
    setLocaleES();
  } else {
    setLocaleCAT();
  }

  const date = dayjs.unix(timestamp);

  // Fix certain formatting options for cat
  const fixMonthNameForCAT = (str: string) => {
    if (lang !== "cat") {
      return str;
    }

    const monthStartsWithVowel = /^\d+ [AEIOU]/i.test(str);

    return str.replace(/^(\d+) /, monthStartsWithVowel ? "$1 d'" : "$1 de ");
  };

  if (Math.abs(dayjs().diff(date, "month")) > 3) {
    // Return format with year
    return fixMonthNameForCAT(date.format(langs.formats.LL));
  }

  // Return format without year
  return fixMonthNameForCAT(date.format(langs.customFormats.Ll));
};

export const formatMinutesForHumans = (
  minutes: number,
  lang?: string
): string => {
  if (lang === "es") {
    setLocaleES();
  } else {
    setLocaleCAT();
  }

  if (minutes < 60) {
    return `${minutes}${langs.minutes_short}`;
  }

  const hours = Math.floor(minutes / 60);
  const minutesLeft = minutes - hours * 60;

  if (!minutesLeft) {
    return `${hours} ${hours > 1 ? langs.hours_large : langs.hour_large}`;
  }

  return `${hours}${langs.hours_short} ${minutesLeft}${langs.minutes_short}`;
};

export const dateBelongsToFormalDay = (date: number, day: number): boolean => {
  const Date = dayjs(date);
  const Day = dayjs(day);
  return Date.isBetween(
    Day.startOf("day").add(1, "hour"),
    Day.startOf("day").add(1, "day").add(1, "hour").add(1, "minute")
  );
};

declare global {
  interface Window {
    dateBelongsToFormalDay: any;
  }
}
window["dateBelongsToFormalDay"] = dateBelongsToFormalDay;
