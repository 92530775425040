/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 *
 * @param {String} asset
 * @param {String?} resize - "1024x768", "1200x0", "0x768", ...
 * @param {String?} options - plain concatenated filter options
 *
 * Filter options:
 * - "b" for black and white
 * - "l1" blur 10px
 * - "l5" blur 50px
 * - "bl1" b&w + blur 10px
 * - ...
 *
 */
export function cdn(
  asset: string,
  resize: any = null,
  options: any = null
): any {
  if (typeof asset !== "string") {
    return null;
  }

  // For easy manipulation, remove the first "/" if present.
  asset = asset.replace(/^\//, "");

  if (resize) {
    // selects the file extension and adds "_{resize}" immediately before
    asset = asset.replace(/(\.[^/.]+)$/, `_${resize}$1`);
  }
  if (options) {
    // idem
    asset = asset.replace(/(\.[^/.]+)$/, `_${options}$1`);
  }

  if (asset.startsWith(process.env.VUE_APP_IMAGES_HOST)) {
    return asset;
  }

  return `${process.env.VUE_APP_IMAGES_HOST}/${asset}`;
}
